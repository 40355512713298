import {connect} from 'react-redux';
import BrokerReviewDashboard from '../components/BrokerReviewDashboard';


import {
    // query
    onQueryInputChange,
    loadBrokerReviewInfo,
    initLoadBrokerReviewInfoActionStatus,
    // select
    selectBrokerReviewInfo,
    // add or edit
    openAddOrEditBrokerReviewModal,
    addOrEditBrokerReviewChangeInput,
    addOrEditBrokerReview,
    initAddOrEditBrokerReviewActionStatus,
    resetAddOrEditBrokerReviewModal,
    // refresh fx and amtUSD
    refreshFx,
    initRefreshFxActionStatus,
    refreshAmtUSD,
    // del
    openDelBrokerReviewModal,
    delBrokerReviewChangeInput,
    delBrokerReview,
    initDelBrokerReviewActionStatus,
    resetDelBrokerReviewModal,

    //cop
    openCopBrokerReviewModal,
    copBrokerReviewChangeInput,
    copBrokerReview,
    initCopBrokerReviewActionStatus,
    resetCopBrokerReviewModal,

    openRestrictedRuleFundBookDialog,
    closeRestrictedRuleFundBookDialog,
    restrictedRuleEditChangeInput,

    
    loadRestrictedRuleList,
    loadRestrictedRuleListPur,
} from '../BrokerReviewActions';

import {
    // operationRcd
    doOperationRecordAction,
} from '../../../common/operationRcd/OperationRecordActions';


const mapStateToProps = state => {
    return {
        // options
        statusOptions: state.brokerReview.statusOptions,
        fundFirmRelatedOptions: state.brokerReview.fundFirmRelatedOptions,
        ccyOptions: state.brokerReview.ccyOptions,
        userAccountOptions: state.brokerReview.userAccountOptions,
        brokerCodeOptions: state.brokerReview.brokerCodeOptions,
        // brokerReviewQueryFields
        brokerReviewQueryFields: state.brokerReview.brokerReviewQueryFields,
        // query
        loadBrokerReviewInfoActionStatus: state.brokerReview.loadBrokerReviewInfoActionStatus,
        loadBrokerReviewInfoResp: state.brokerReview.loadBrokerReviewInfoResp,
        selectedBrokerReviewInfoArray: state.brokerReview.selectedBrokerReviewInfoArray,
        brokerReviewGridColumns: state.brokerReview.brokerReviewGridColumns,
        // add or edit
        addOrEditBrokerReviewModal: state.brokerReview.addOrEditBrokerReviewModal,
        addOrEditBrokerReviewStatus: state.brokerReview.addOrEditBrokerReviewStatus,
        // refresh fx and amtUSD
        refreshFxStatus: state.brokerReview.refreshFxStatus,
        refreshFxResp: state.brokerReview.refreshFxResp,
        // del
        delBrokerReviewModal: state.brokerReview.delBrokerReviewModal,
        delBrokerReviewStatus: state.brokerReview.delBrokerReviewStatus,
        // cop
        copBrokerReviewModal: state.brokerReview.copBrokerReviewModal,
        copBrokerReviewStatus: state.brokerReview.copBrokerReviewStatus,
        // opeartionRcd
        operationRecord: state.operationRecord,

        brokerReviewUploadUrl: state.brokerReview.brokerReviewUploadUrl,
        brokerReviewDownloadUrl: state.brokerReview.brokerReviewDownloadUrl,
        restrictedRuleEditControlModal: state.brokerReview.restrictedRuleEditControlModal,
        queryParams: state.brokerReview.queryParams,

        restrictedRuleList:  state.brokerReview.restrictedRuleList,
        restrictedRuleListPur:  state.brokerReview.restrictedRuleListPur,
    };
}


const mapDispatchToProps = dispatch => {
    return {
        // query
        onQueryInputChange: (params) => dispatch(onQueryInputChange(params)),
        loadBrokerReviewInfo: (params) => dispatch(loadBrokerReviewInfo(params)),
        initLoadBrokerReviewInfoActionStatus: () => dispatch(initLoadBrokerReviewInfoActionStatus()),
        // select
        selectBrokerReviewInfo: (dataArray) => dispatch(selectBrokerReviewInfo(dataArray)),
        // add or edit
        openAddOrEditBrokerReviewModal: (mode, data) => dispatch(openAddOrEditBrokerReviewModal(mode, data)),
        addOrEditBrokerReviewChangeInput: (params) => dispatch(addOrEditBrokerReviewChangeInput(params)),
        addOrEditBrokerReview: (info) => dispatch(addOrEditBrokerReview(info)),
        initAddOrEditBrokerReviewActionStatus: () => dispatch(initAddOrEditBrokerReviewActionStatus()),
        resetAddOrEditBrokerReviewModal: () => dispatch(resetAddOrEditBrokerReviewModal()),
        // refresh fx and amtUSD
        refreshFx: (params) => dispatch(refreshFx(params)),
        initRefreshFxActionStatus: () => dispatch(initRefreshFxActionStatus()),
        refreshAmtUSD: (params) => dispatch(refreshAmtUSD(params)),
        // del
        openDelBrokerReviewModal: (info,mode,idList) => dispatch(openDelBrokerReviewModal(info,mode,idList)),
        delBrokerReviewChangeInput: (params) => dispatch(delBrokerReviewChangeInput(params)),
        delBrokerReview: (info) => dispatch(delBrokerReview(info)),
        initDelBrokerReviewActionStatus: () => dispatch(initDelBrokerReviewActionStatus()),
        resetDelBrokerReviewModal: () => dispatch(resetDelBrokerReviewModal()),

        // cop
        openCopBrokerReviewModal: (info) => dispatch(openCopBrokerReviewModal(info)),
        copBrokerReviewChangeInput: (params) => dispatch(copBrokerReviewChangeInput(params)),
        copBrokerReview: (params) => dispatch(copBrokerReview(params)),
        initCopBrokerReviewActionStatus: () => dispatch(initCopBrokerReviewActionStatus()),
        resetCopBrokerReviewModal: () => dispatch(resetCopBrokerReviewModal()),

        // operationRcd
        
        doOperationRecordAction: (params) => dispatch(doOperationRecordAction(params)),


        openRestrictedRuleFundBookDialog: () => dispatch(openRestrictedRuleFundBookDialog()),
        closeRestrictedRuleFundBookDialog: () => dispatch(closeRestrictedRuleFundBookDialog()),
        restrictedRuleEditChangeInput: (params) => dispatch(restrictedRuleEditChangeInput(params)),





        loadRestrictedRuleList: (params) => dispatch(loadRestrictedRuleList(params)),

        loadRestrictedRuleListPur: (params) => dispatch(loadRestrictedRuleListPur(params)),

    };
}


export default connect (
    mapStateToProps,
    mapDispatchToProps
)(BrokerReviewDashboard);