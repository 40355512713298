import React, {Component} from 'react';
import { Breadcrumb, Select, DatePicker, Button, message, Input , Tabs} from 'antd';
import { ACTION_STATUS } from '../../../../../utils/ActionStatus';
import './BrokerReviewDashboard.css';
import BrokerReviewGrid from './BrokerReviewGrid';
import AddOrEditBrokerReviewModalDialog from './AddOrEditBrokerReviewModalDialog';
import DelBrokerReviewModalDialog from './DelBrokerReviewModalDialog';
import CopBrokerReviewModalDialog from './CopBrokerReviewModalDialog';
import OperationRecordDialog from '../../../common/operationRcd/components/OperationRecordDialog';
import BrokerReviewSummary from './BrokerReviewSummary';
import BrokerReviewSummaryPur from './BrokerReviewSummaryPur';
const TabPane = Tabs.TabPane;
class BrokerReviewDashboard extends Component {
    componentDidMount() {
        const {
            pinPointContact,
            interactionPurpose,
            beginDate,
            endDate
        } = this.props.brokerReviewQueryFields;
        this.props.loadBrokerReviewInfo({pinPointContact: undefined,interactionPurpose: 'Analyst Marketing',beginDate: undefined,endDate: undefined,brokerCode:undefined})

    }


    componentDidUpdate() {
        const {
            // query
            loadBrokerReviewInfoActionStatus,
            loadBrokerReviewInfoResp,
        } = this.props;

        if(loadBrokerReviewInfoActionStatus === ACTION_STATUS.SUCCESS) {
            this.props.initLoadBrokerReviewInfoActionStatus();
        }
        if(loadBrokerReviewInfoActionStatus === ACTION_STATUS.ERROR) {
            let err = 'Load User Access Info failed';
            if(!!loadBrokerReviewInfoResp && !!loadBrokerReviewInfoResp.respMessage) {
                err = loadBrokerReviewInfoResp.respMessage;
            }
            message.error(err);
            this.props.initLoadBrokerReviewInfoActionStatus();
        }

    }


    onInputChange = ({ name, value }) => {
        this.props.onQueryInputChange({ name, value });
    };

    batchReplace = (mode) => {
        if(mode === 'ADD') {
            this.props.openAddOrEditBrokerReviewModal(mode, null);
        } else {
            const {
                selectedBrokerReviewInfoArray,
            } = this.props;
            if(selectedBrokerReviewInfoArray.length === 0) {
                message.warning("Please choose one and  User Access Info");
                return;
            }
            const idList = selectedBrokerReviewInfoArray.map(e => e.id);
            // this.props.openAddOrEditPortfolioMonitoringRuleSubModal(mode, null,idList);
            const currentBrokerReviewInfo = selectedBrokerReviewInfoArray[0];
            const ids_str = idList.join(',');
            this.props.openDelBrokerReviewModal(currentBrokerReviewInfo,mode,ids_str);
            // this.props.openDelBrokerReviewModal(currentBrokerReviewInfo,mode);
        }
    }
    addOrEditBtnOnClicked = (mode) => {
        if(mode === 'ADD') {
            this.props.openAddOrEditBrokerReviewModal(mode, null);
        } else {
            const {
                selectedBrokerReviewInfoArray,
            } = this.props;
            if(selectedBrokerReviewInfoArray.length !== 1) {
                message.warning("Please choose one and only one User Access Info");
                return;
            }
            const currentBrokerReviewInfo = selectedBrokerReviewInfoArray[0];
            this.props.openAddOrEditBrokerReviewModal(mode, currentBrokerReviewInfo);
        }
    }
    copyAllBtnOnClicked = () => {
        const {
            selectedBrokerReviewInfoArray,
        } = this.props;
        if(selectedBrokerReviewInfoArray.length !== 1) {
            message.warning("Please choose one and only one User Access Info");
            return;
        }
        const currentBrokerReviewInfo = selectedBrokerReviewInfoArray[0];
        this.props.openCopBrokerReviewModal(currentBrokerReviewInfo);
    }

    delBtnOnClicked = (mode) => {
        const {
            selectedBrokerReviewInfoArray,
        } = this.props;
        if(selectedBrokerReviewInfoArray.length !== 1) {
            message.warning("Please choose one and only one User Access Info");
            return;
        }
        const currentBrokerReviewInfo = selectedBrokerReviewInfoArray[0];
        this.props.openDelBrokerReviewModal(currentBrokerReviewInfo,mode);
    }


    refreshBtnOnClicked = () => {
        const {
            pinPointContact,
            interactionPurpose,
            beginDate,
            endDate,
            brokerCode
            
        } = this.props.brokerReviewQueryFields;
        let pur = interactionPurpose
        let code = brokerCode
        if (interactionPurpose === 'ALL'){
            pur=undefined
        }
        if (brokerCode === 'ALL'){
            code=undefined
        }
        this.props.loadBrokerReviewInfo({pinPointContact,interactionPurpose:pur,beginDate,endDate,brokerCode:code})
    }


    // 操作记录页面，自定义需要展示的列
    opRcdCustomColumns = ['operator', 'operateTime', 'dataId', 'operateType', 'comments', 'before', 'after'];


    render() {

        const {
            statusOptions,
            userAccountOptions,
            brokerCodeOptions,
            brokerReviewQueryFields,
            loadBrokerReviewInfoActionStatus,
            queryParams,
        } = this.props;

        const {
            userName,
            status,
        } = brokerReviewQueryFields;


        const refreshBtnIsLoading = loadBrokerReviewInfoActionStatus === ACTION_STATUS.LOGINING;



        return (
            <div className='BrokerReviewWrapper'>
                <div className='breadcrumbWrapper'>
                    <Breadcrumb>
                        <Breadcrumb.Item></Breadcrumb.Item>
                        <Breadcrumb.Item>Broker Review</Breadcrumb.Item>
                    </Breadcrumb>
                </div>
                <Tabs defaultActiveKey="1" tabPosition={'top'}>
                    <TabPane  tab="Broker Review" key="1">、
                        <div className='OperationBar'>
                            <div className='breadcrumbWrapper'>   
                            <Breadcrumb>
                            <Breadcrumb.Item>  Search by:</Breadcrumb.Item>
                            </Breadcrumb>
                            </div>

                        

                            <Select
                                allowClear
                                size='small'
                                style={{ width: 200, marginLeft: 10 }}
                                onChange={(val) => {
                                    this.onInputChange({ name: 'brokerCode', value: val })
                                }} 
                                placeholder="brokerCode">
                                {brokerCodeOptions}
                            </Select>

                            <Select
                                allowClear
                                showSearch
                                size='small'
                                // --value={!user ? undefined : user}
                                style={{marginLeft: 10, width: 150}}
                                //onChange={(value) => this.onInputChange({name: 'userName', value: value})}
                                onChange={(val) => {
                                    this.onInputChange({ name: 'pinPointContact', value: val })
                                }} 
                                placeholder="UserName">
                                {userAccountOptions}
                            </Select> 
                            <Select
                                // defaultValue="Analyst Marketing"
                                allowClear
                                size='small'
                                style={{ width: 200, marginLeft: 10 }}
                                onChange={(val) => {
                                    this.onInputChange({ name: 'interactionPurpose', value: val })
                                }} 
                                placeholder="interactionPurpose">
                                {statusOptions}
                            </Select>
                            <DatePicker 
                            allowClear={true}
                            size='small'
                            style={{ width: 150, marginLeft: 10 }}
                            placeholder="Date Start"
                            onChange={(invDateStart, dateString) => {
                                this.onInputChange({ name: 'beginDate', value: dateString });
                            }}
                            />
                            <DatePicker 
                            allowClear={true}

                            size='small'
                            style={{ width: 150, marginLeft: 10 }}
                            placeholder="Date End"
                            onChange={(invDateEnd, dateString) => {
                                this.onInputChange({ name: 'endDate', value: dateString });
                            }}
                            /> 
                            <Button 
                                onClick={this.refreshBtnOnClicked} 
                                loading={refreshBtnIsLoading}
                                size='small'
                                style={{ marginLeft: 10 }}
                                type='primary'
                                icon="search" 
                            />
                            <Button 
                                onClick={()=>this.batchReplace('REPORT')} 
                                size='small'
                                style={{ fontSize: 10, marginLeft: 10 }}
                                className='positive-button'>
                                REPORT
                            </Button>
                            
                            <Button 
                                onClick={()=>this.props.doOperationRecordAction({action:'OPEN_AND_QUERY', queryFields: {module: 'BrokerReview'}, customColumns: this.opRcdCustomColumns})} 
                                size='small'
                                style={{ fontSize: 10, marginLeft: 10 }}
                                type='primary'>
                                Operation Record
                            </Button>
                        </div>

                        <div className='BrokerReviewGrid'><BrokerReviewGrid {...this.props} /></div>
                        <AddOrEditBrokerReviewModalDialog {...this.props} />
                        <DelBrokerReviewModalDialog {...this.props} />
                        <CopBrokerReviewModalDialog {...this.props} />
                        <OperationRecordDialog {...this.props} />
                    </TabPane>
                    <TabPane tab="Resource summary" key="2">
                    <BrokerReviewSummary {...this.props}/> 
                    </TabPane>
                    <TabPane tab="Purpose Summary" key="3">
                    <BrokerReviewSummaryPur {...this.props}/> 
                    </TabPane>
                </Tabs>
            </div>
        );
    }
}

export default BrokerReviewDashboard;
