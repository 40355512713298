import {connect} from 'react-redux';
import FundLevelReportDashboard from '../components/FundLevelReportDashboard';

import {
  // Generate Fund Level Report
  generateFundLevelReport,
  initGenerateFundLevelReportActionStatus,

  // Load Fund Level Report
  loadFundLevelReport,
  initLoadFundLevelReportActionStatus,

  // load group info
  listFundPosGroupInfo,
  initListFundPosGroupInfoActionStatus,
  
  // Filter Long More Than 2% Fund Level Report
  filterLongMoreThan2PercentFundLevelReport,

  // Filter Long More Than 5% Fund Level Report
  filterLongMoreThan5PercentFundLevelReport,

  // Filter Short More Than 2% Fund Level Report
  filterShortMoreThan2PercentFundLevelReport,

  // Filter Short More Than 5% Fund Level Report
  filterShortMoreThan5PercentFundLevelReport,

  // Show All Fund Level Report
  showAllFundLevelReport,

  // add grid to container
  fundPositionGroupInfoGridToProps,
  fundLevelReportGridToProps,


} from '../fundLevelReportActions';

const mapStateToProps = state => {
  return {
    // Grdi Columns
    gridType: state.fundLevelReport.gridType,
    fundLevelReportGridColumns: state.fundLevelReport.fundLevelReportGridColumns,
    fundPositionGroupInfoGridColumns: state.fundLevelReport.fundPositionGroupInfoGridColumns,

    // Fund Level Report
    fundLevelReportArray: state.fundLevelReport.fundLevelReportArray,
    filterFundLevelReportArray: state.fundLevelReport.filterFundLevelReportArray,

    // Generate Fund Level Report Action Status
    generateFundLevelReportActionStatus: state.fundLevelReport.generateFundLevelReportActionStatus,
    generateFundLevelReportErrMsg: state.fundLevelReport.generateFundLevelReportErrMsg,

    // Load Fund Level Report Action Status
    loadFundLevelReportActionStatus: state.fundLevelReport.loadFundLevelReportActionStatus,
    loadFundLevelReportErrMsg: state.fundLevelReport.loadFundLevelReportErrMsg,

    // Load Fund Level Report
    listFundPosGroupInfoActionStatus: state.fundLevelReport.listFundPosGroupInfoActionStatus,
    listFundPosGroupInfoActionResp: state.fundLevelReport.listFundPosGroupInfoActionResp,

    // add grid to container
    fundPositionGroupInfoGrid: state.fundLevelReport.fundPositionGroupInfoGrid,
    fundLevelReportGrid: state.fundLevelReport.fundLevelReportGrid,

  };
} 

const mapDispatchToProps = dispatch => {
  return {
    // Generate Fund Level Report
    generateFundLevelReport: (dateString, dataType) => dispatch(generateFundLevelReport(dateString, dataType)),
    initGenerateFundLevelReportActionStatus: () => dispatch(initGenerateFundLevelReportActionStatus()),

    // Load Fund Level Report
    loadFundLevelReport: (dateString, dataType,guid) => dispatch(loadFundLevelReport(dateString, dataType,guid)),
    initLoadFundLevelReportActionStatus: () => dispatch(initLoadFundLevelReportActionStatus()),

    // load group info
    listFundPosGroupInfo: (dateString, dataType) => dispatch(listFundPosGroupInfo(dateString, dataType)),
    initListFundPosGroupInfoActionStatus: () => dispatch(initListFundPosGroupInfoActionStatus()),

    // // Filter Long More Than 2% Fund Level Report
    // filterLongMoreThan2PercentFundLevelReport: () => dispatch(filterLongMoreThan2PercentFundLevelReport()),

    // // Filter Long More Than 5% Fund Level Report
    // filterLongMoreThan5PercentFundLevelReport: () => dispatch(filterLongMoreThan5PercentFundLevelReport()),

    // // Filter Short More Than 2% Fund Level Report
    // filterShortMoreThan2PercentFundLevelReport: () => dispatch(filterShortMoreThan2PercentFundLevelReport()),

    // // Filter Short More Than 5% Fund Level Report
    // filterShortMoreThan5PercentFundLevelReport: () => dispatch(filterShortMoreThan5PercentFundLevelReport()),

    // Show All Fund Level Report
    showAllFundLevelReport: () => dispatch(showAllFundLevelReport()),

    // add grid to container
    fundPositionGroupInfoGridToProps: (grid) => dispatch(fundPositionGroupInfoGridToProps(grid)),
    fundLevelReportGridToProps: (grid) => dispatch(fundLevelReportGridToProps(grid)),

  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FundLevelReportDashboard);